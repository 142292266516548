"use client";
import { create } from "zustand";
import IconCloseX from "@/components/ui/Icons/IconCloseX";

interface SellErrorBannerState {
  openSellErrorBanner: boolean;
  setSellErrorBanner: (openSellErrorBanner: boolean) => void;
}

export const useBannerSellErrorStore = create<SellErrorBannerState>((set) => ({
  openSellErrorBanner: false,
  setSellErrorBanner: (openSellErrorBanner) => set({ openSellErrorBanner }),
}));

export default function BannerBuyError() {
  const { openSellErrorBanner, setSellErrorBanner } = useBannerSellErrorStore();

  return (
    <>
      {openSellErrorBanner && (
        <div
          style={{
            zIndex: 9999,
          }}
          className="h-[220px] lg:h-[120px] shadow-xl  w-full sticky top-0 left-0 flex p-3 lg:p-6 items-center 
          justify-center bg-gradient-to-br from-red-pink via-red-pink to-primary"
        >
          <div className="flex items-center justify-center w-full gap-4">
            <div className="grid w-auto justify-center mt-4 ">
              <div className="text-left font-medium text-lg tracking-wide">
                Hmmm... Something went wrong.
              </div>
              <div className="mt-2 grid gap-4 lg:gap-6 md:flex items-center gap-x-6 w-full ">
                <div className="text-[16px]">
                  Your for-sale offer could not be created. Please try again
                  later.
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => setSellErrorBanner(false)}
            className="absolute top-6 right-6 rounded-md p-1 bg-white/10 hover:bg-white hover:text-primary transition"
          >
            <IconCloseX />
          </button>
        </div>
      )}
    </>
  );
}
